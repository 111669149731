import { Box, Center, Flex } from "@chakra-ui/react";

interface IBubble {
  fromUser?: boolean;
}

const Bubble = (props: React.PropsWithChildren<IBubble>): JSX.Element => {
  return (
    <>
      {/* @ts-ignore */}
      <Flex w="100%" justifyContent="space-between">
        {props.fromUser && <Box w="1px" />}
        <Box
          mb="8px"
          p="8px"
          maxW="90%"
          borderRadius="12px"
          bg={props.fromUser ? "green" : "white"}
          textAlign={props.fromUser ? "right" : "left"}
          w="fit-content"
          color="black"
        >
          {props.children}
        </Box>
      </Flex>
    </>
  );
};

export default Bubble;
