import * as React from "react";
import { Box, Center } from "@chakra-ui/react";

interface IWrapper {}

const Wrapper = (props: React.PropsWithChildren<IWrapper>): JSX.Element => {
  return (
    <Box bg="#FFF8F0" minH="-webkit-fill-available" minW="100vw">
      <Center h="100vh">
        <Box
          w={{ base: "100vw", md: "390px" }}
          h={{ base: "100vh", md: "844px" }}
          outline="5px solid black"
          boxShadow="0px 0px 20px 2px rgba(0, 0, 0, .7)"
          overflowX="hidden"
          borderRadius="40px"
          position="relative"
          bg="url(bg.png)"
          bgSize="cover"
        >
          <Box zIndex="100" pos="absolute" h="100%" w="100%">
            {props.children}
          </Box>
          <Box
            position="absolute"
            h="90%"
            w="90%"
            borderRadius="40px"
            top="5%"
            left="5%"
            bg="rgba(255,255,255,.5)"
            backdropFilter="blur(20px)"
          />
        </Box>
      </Center>
    </Box>
  );
};

export default Wrapper;
