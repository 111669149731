import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
  Center,
  Flex,
  Box,
  Button,
  useFocusEffect,
} from "@chakra-ui/react";
import { useState, useRef, useEffect } from "react";
import Answers from "../answers.json";

interface ISliderC {
  callback: (v: number) => void;
  back: () => void;
  page: number;
}

const SliderC = ({ callback, page, back }: ISliderC): JSX.Element => {
  const [step, setStep] = useState(0);
  const [label, setLabel] = useState("");

  function handleStepChange(s: number) {
    let label = "";
    switch (s) {
      case 0:
        label = Answers[page].answers[0];
        break;
      case 25:
        label = Answers[page].answers[1];
        break;
      case 50:
        label = Answers[page].answers[2];
        break;
      case 75:
        label = Answers[page].answers[3];
        break;
      case 100:
        label = Answers[page].answers[4];
        break;
      default:
        break;
    }
    setLabel(label);
  }

  function handleClick() {
    callback(step);
  }

  useEffect(() => {
    handleStepChange(step);
  }, [page]);

  return (
    <>
      {/* @ts-ignore */}
      <Flex mb="24px" mt="24px" justifyContent="space-between">
        <Box
          w="100%"
          m="24px"
          mb="0"
          textAlign="center"
          p="8px"
          bg="#7B0110"
          color="white"
          borderRadius="12px"
          transition="all 0.3s"
          opacity={page === 1 ? 0 : 1}
          pointerEvents={page === 1 ? "none" : "auto"}
        >
          {label}
        </Box>
      </Flex>
      {/* @ts-ignore */}
      <Slider
        opacity={page === 1 ? 0 : 1}
        pointerEvents={page === 1 ? "none" : "auto"}
        transition="all 0.3s"
        onChange={(v) => handleStepChange(v)}
        onChangeEnd={(v) => setStep(v)}
        defaultValue={50}
        min={0}
        max={100}
        step={25}
      >
        <SliderTrack bg="red.100">
          <Box position="relative" right={10} />
          <SliderFilledTrack bg="#7B0110" />
        </SliderTrack>
        <SliderThumb boxSize={6} />
      </Slider>
      <Flex>
        <Button
          boxShadow="0px 0px 10px rgba(0,0,0,.2)"
          mt="30px"
          ml="20px"
          w="30%"
          h="60px"
          bg="lightgray"
          onClick={() => back()}
        >
          Back
        </Button>
        <Center w="200px" h="80px" mt="16px">
          <Box
            borderRadius="48px"
            padding="4px"
            position="relative"
            overflow="hidden"
          >
            <Box
              bg="url(character.png) center center"
              bgSize="cover"
              w="80px"
              h="80px"
              borderRadius="40px"
              zIndex="100"
            />
            <Box
              h="100px"
              w="100px"
              top={`${100 - 10.6 * page}px`}
              transition="all 0.2s ease-out"
              left="0px"
              position="absolute"
              bg="#14AE5C"
              zIndex="-1"
            />
          </Box>
        </Center>
        <Button
          boxShadow="0px 0px 10px rgba(0,0,0,.2)"
          mt="30px"
          color="white"
          bg="#7B0110"
          mr="20px"
          w="30%"
          h="60px"
          _hover={{}}
          onClick={() => handleClick()}
        >
          {page === 1 ? "Sure" : "Answer"}
        </Button>
      </Flex>
    </>
  );
};

export default SliderC;
