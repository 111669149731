import * as React from "react";
import { ChakraProvider, theme, Box, Flex } from "@chakra-ui/react";
import Wrapper from "./components/Wrapper";
import SliderC from "./components/SliderC";
import Bubble from "./components/Bubble";
import Page from "./components/Page";

export const App = () => (
  <ChakraProvider theme={theme}>
    {/* @ts-ignore */}
    <Wrapper>
      <Page />
    </Wrapper>
  </ChakraProvider>
);
