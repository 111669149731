import {
  Box,
  Button,
  Center,
  Editable,
  Flex,
  Heading,
  Input,
  List,
  Textarea,
  useClipboard,
  useFocusEffect,
} from "@chakra-ui/react";
import Bubble from "./Bubble";
import SliderC from "./SliderC";
import { useState, useRef, useEffect } from "react";
import Data from "../content.json";

interface IPage {}

interface IState {
  name: string;
  answers: [IAnswer];
}

interface IAnswer {
  page: number;
  answer: number;
  timeUsed: number;
}

interface IContent {
  number: number;
  desc: string;
  from: string;
  content: string[];
  reaction?: string[];
}

interface IBubbleList {
  content: IContent[];
  lastAnswer?: number;
}

const BubbleList = ({ content, lastAnswer }: IBubbleList): JSX.Element => {
  return (
    <>
      {/* @ts-ignore */}
      <List>
        {content.map((element) => {
          return (
            // @ts-ignore
            <Box key={"pagebubble_" + element.desc} transition="all 0.2s">
              {element.reaction?.map((entry, i) => {
                return i === lastAnswer ? (
                  <Bubble
                    key={"bubble_" + element.desc + i + "reaction"}
                    fromUser={element.from === "user"}
                  >
                    {entry}
                  </Bubble>
                ) : (
                  <></>
                );
              })}
              {element.content.map((entry, i) => {
                return (
                  <Bubble
                    key={"bubble_" + element.desc + i}
                    fromUser={element.from === "user"}
                  >
                    {entry}
                  </Bubble>
                );
              })}
            </Box>
          );
        })}
      </List>
    </>
  );
};

const Page = (props: React.PropsWithChildren<IPage>): JSX.Element => {
  // Time
  const [clickCount, setClickCount] = useState(0);
  const [startTime, setStartTime] = useState<number>(-1);
  const [timeBetweenClicks, setTimeBetweenClicks] = useState<number>(-1);

  const [bigState, setBigState] = useState<IState>({
    name: "",
    answers: [{ page: -1, answer: -1, timeUsed: -1 }],
  });

  const [currentPage, setCurrentPage] = useState(0);

  const dialogue = useRef<HTMLDivElement>(null);

  const [content, setContent] = useState([Data[0]]);
  const [lastAnswer, setLastAnswer] = useState(0);

  useEffect(() => {
    if (clickCount === 0) {
      setStartTime(-1);
      setTimeBetweenClicks(-1);
    } else if (clickCount === 1) {
      setStartTime(Date.now());
    } else {
      setTimeBetweenClicks(Date.now() - startTime);
      setStartTime(Date.now());
    }
  }, [clickCount]);

  function getPage(number: number) {
    return Data[number];
  }

  function nextPage() {
    // const c = content;
    // c.push(Data[currentPage + 1]);
    let c = Data[currentPage + 1];
    setContent([c]);
    setClickCount(clickCount + 1);
  }

  function addAnswer(value: IAnswer) {
    let state = bigState;
    state?.answers.push(value);
    setBigState(state);
    console.log("New state: ", state);
    setLastAnswer((value.answer / 100) * 4);
  }

  const [lastPageActive, setLastPageActive] = useState(false);

  const nextPageCallback = (v: number) => {
    if (currentPage + 2 > Data.length) {
      console.log("last page");
      setLastPageActive(true);
    } else {
      console.log("Next page");
      let metrics = {
        page: currentPage,
        answer: v,
        timeUsed: timeBetweenClicks,
      };
      addAnswer(metrics);
      setCurrentPage(currentPage + 1);
      nextPage();
    }
  };

  const firstPage = () => {
    setCurrentPage(1);
    nextPage();
  };

  const goBack = () => {
    setCurrentPage(currentPage - 1);
    let c = Data[currentPage - 1];
    setContent([c]);
  };

  const placeholder = "text to be copied...";
  const { onCopy, value, setValue, hasCopied } = useClipboard("");

  useEffect(() => {
    setValue(JSON.stringify(bigState));
  }, [bigState]);

  return (
    <>
      <Box display={!lastPageActive ? "block" : "none"}>
        <Box display={currentPage === 0 ? "block" : "none"} m="32px" mt="80px">
          <Box>
            <b>Welcome to the Hoge Woerd Museum.</b>
            <br />
            <br />
            This is your personal guide through our museum. If you are ready to
            start your journey, talk to Brutus.
          </Box>

          <Center w="100%" h="200px">
            <Box>
              <Box
                bg="url(brutus.png) center center"
                bgSize="cover"
                w="80px"
                h="80px"
                borderRadius="40px"
                mr="24px"
                mt="8px"
              />
            </Box>
            <Button mt="8px" onClick={() => firstPage()} size="lg">
              Talk
            </Button>
          </Center>
        </Box>
        <Box display={currentPage !== 0 ? "block" : "none"}>
          <Box m="32px" color="white">
            {/* @ts-ignore */}
            <Center w="100%" h="80px" mb="16px">
              <Box
                bg="url(brutus.png) center center"
                bgSize="cover"
                w="80px"
                h="80px"
                borderRadius="40px"
              />
            </Center>
            <Box ref={dialogue}>
              <BubbleList content={content} lastAnswer={lastAnswer} />
            </Box>
          </Box>
          <Box position="absolute" left="24px" right="24px" bottom="24px">
            <SliderC
              page={currentPage}
              back={() => goBack()}
              callback={(v) => nextPageCallback(v)}
            />
          </Box>
        </Box>
      </Box>
      <Box
        display={lastPageActive ? "block" : "none"}
        mt="80px"
        ml="64px"
        mr="64px"
      >
        <Heading mb="32px">Thats it!</Heading>
        <p>
          Thank you for testing the prototype. Please copy this text and paste
          it back at the form.
        </p>
        <Box mt="32px">
          <Box
            p="16px"
            maxH="300px"
            overflowY="scroll"
            bg="white"
            rounded="20px"
          >
            {JSON.stringify(bigState)}
          </Box>
          {/* <Flex mb={2}>
            <Input
              placeholder={JSON.stringify(bigState)}
              value={value}
              contentEditable={false}
              mr={2}
            />
            <Button colorScheme="green" onClick={onCopy}>
              {hasCopied ? "Copied!" : "Copy"}
            </Button>
          </Flex> */}
        </Box>
      </Box>
    </>
  );
};

export default Page;
